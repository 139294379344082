import React, { useEffect, useState } from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { CustomInputField } from "../../common/FormInputs";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import _ from "lodash";

const UpdateSpeaker = React.memo(
  ({ state, setState, speakersData, isEditable, editor, setCursor }) => {
    const filter = createFilterOptions();
    const [value, setValue] = useState();
    const [element, setElement] = useState();
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      function handleEscapeKey(event) {
        if (event.code === "Escape") {
          setState({ state: false, speakerIdendification: false });
        }
      }

      document.addEventListener("keydown", handleEscapeKey);
      return () => document.removeEventListener("keydown", handleEscapeKey);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
      if (state?.state) {
        setElement(state?.element);
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.state]);

    const handleChangeAllSpeaker = (value, oldSpeaker, editor) => {
      if (isEditable) {
        if (value) {
          const rangeForTheWholeEditor = Editor.range(editor, []);
          // Apply transformation to the whole doc, where speaker matches old spekaer name, and set it to new one
          Transforms.setNodes(
            editor,
            { type: "timedText", speaker: value, oldSpeaker: oldSpeaker },
            {
              at: rangeForTheWholeEditor,
              match: (node) =>
                node.type === "timedText" &&
                _.lowerCase(node.speaker) === _.lowerCase(oldSpeaker),
            },
          );
          setState({ state: false, speakerIdendification: true });
        }
      }
    };

    const handleChangeSpeaker = (value, editor) => {
      const pathToCurrentNode = ReactEditor.findPath(editor, element);
      if (value) {
        Transforms.setNodes(
          editor,
          { type: "timedText", speaker: value, oldSpeaker: element?.speaker },
          { at: pathToCurrentNode },
        );
        setState({
          state: false,
          selection: state.selection,
          speakerIdendification: false,
        });
        setCursor({ selection: state.selection });
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Tab") {
        // event.preventDefault(); // Prevent the default tab behavior
        if (speakersData.length > 0 && event?.target?.value?.length) {
          const filteredData = speakersData.filter((item) =>
            item.toLowerCase().includes(event?.target?.value.toLowerCase()),
          );
          if (filteredData?.length) {
            setValue(filteredData[0]);
            setInputValue(filteredData[0]);
          } else {
            setValue(event?.target?.value);
            setInputValue(event?.target?.value);
          }
        }
      }
    };

    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
    };

    return (
      <Modal
        open={state?.state}
        onClose={() => setState({ state: false, speakerIdendification: false })}
      >
        <DrawerContainer>
          <HeadingContainer>
            <Heading>{"Change Speaker"}</Heading>
            <CloseModal
              onClick={() =>
                setState({ state: false, speakerIdendification: false })
              }
              src={require("../../../../src/assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <NoteContainer>
            <Autocomplete
              onChange={(event, value) => setValue(value)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option,
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              disableClearable
              popupIcon={false}
              clearOnBlur={false}
              options={speakersData}
              value={value}
              getOptionLabel={(option) => option.toString()}
              renderInput={(params) => (
                <CustomInputField
                  variant="outlined"
                  {...params}
                  autoFocus
                  onKeyDown={handleKeyDown}
                  placeholder={element?.speaker}
                />
              )}
            />
          </NoteContainer>
          <ButtonWrapper
            style={{
              margin: "auto",
              width: "418px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "52px",
            }}
          >
            <PrimaryOutlinedCTAButton
              style={{
                height: "48px",
                width: "162px",
                marginRight: 20,
              }}
              onClick={() => handleChangeSpeaker(value, editor)}
            >
              Change One
            </PrimaryOutlinedCTAButton>
            <PrimaryCTAButton
              type={"submit"}
              onClick={() =>
                handleChangeAllSpeaker(value, element?.speaker, editor)
              }
              style={{ height: "48px", width: "162px" }}
            >
              Change All
            </PrimaryCTAButton>
          </ButtonWrapper>
        </DrawerContainer>
      </Modal>
    );
  },
);

export default UpdateSpeaker;

const DrawerContainer = styled.div`
  max-width: 446px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 48px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
`;
