import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CircularProgress, Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import {
  CustomCheckbox,
  CustomInputField,
  CustomRadioGroup,
} from "../FormInputs";
import CaseService from "../../../services/CaseService";
import _ from "lodash";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { encrypt, renderFeeKind } from "../../../helpers/functions";
import { PAYMENT_STATUS } from "../../../helpers/constants";
import { refundScheme } from "../../../helpers/validationSchema";
import { FieldArray, Form, Formik } from "formik";
import NumberFormat from "react-number-format";
import { Fragment } from "react";

export default function RefundModal({
  openRefundModal,
  setOpenRefundModal,
  id = "",
  heading = "",
  btnText = "",
  viewOnly,
}) {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getPaymentHistory(id) {
      try {
        setLoading(true);
        const response = await CaseService.getPayments(id);
        if (response?.data) {
          setPaymentHistory(response?.data);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }

    if (id && openRefundModal) {
      getPaymentHistory(id);
    }
  }, [id, openRefundModal]);

  const submitForRefund = async (payload) => {
    try {
      setLoader({ state: true, message: `Submitting for refund ...` });
      const res = await CaseService.refundPayment(payload);
      if (res?.message) {
        enqueueSnackbar(res?.message, { variant: "success" });
      }
    } catch (error) {
      // const message = getErrorMessage(error);
      enqueueSnackbar("Unable to process Refund", { variant: "error" });
    } finally {
      setLoader({ state: false });
      setOpenRefundModal(false);
    }
  };

  const onFormSubmit = (values) => {
    const payload = {};
    payload.refundComment = values?.comment;
    payload.refunds = values?.payments
      ?.filter((payment) => payment.checked)
      .map((el) => {
        return {
          id: el?.id,
          value:
            el?.type === "full"
              ? encrypt(
                  Number(
                    el?.cartItem?.fee?.discPrice
                      ?.toString()
                      ?.split(",")
                      ?.join(""),
                  ),
                )
              : encrypt(Number(el?.amount?.toString()?.split(",")?.join(""))),
        };
      });
    submitForRefund(payload);
  };

  return (
    <Modal
      open={openRefundModal}
      onClose={() => setOpenRefundModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{heading}</Heading>
          <CloseModal
            onClick={() => setOpenRefundModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        {loading ? (
          <div
            style={{
              display: "flex",
              height: 200,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Formik
            initialValues={{
              payments: paymentHistory?.map((el) => {
                return {
                  ...el,
                  amount: el?.cartItem?.fee?.discPrice,
                  type: "full",
                  checked: false,
                };
              }),
              comment: "",
            }}
            validationSchema={refundScheme}
            enableReinitialize={false}
            onSubmit={onFormSubmit}
            validateOnBlur
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldTouched,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <NoteContainer>
                    <div className="sub-heading">Payment history</div>
                    <div>
                      {/* <!-- Payment history section --> */}
                      <FieldArray
                        name="payments"
                        render={() => (
                          <Fragment>
                            {values?.payments?.map((payment, index) => (
                              <Fragment key={index}>
                                <div className="history-row">
                                  <div className="content">
                                    <div>
                                      {renderFeeKind(
                                        PAYMENT_STATUS[
                                          payment?.cartItem?.feeKind
                                        ],
                                        payment?.case,
                                      )}{" "}
                                      fee (
                                      {_.startCase(
                                        payment?.cartItem?.agentRole,
                                      )}
                                      )
                                    </div>
                                    <div>
                                      {payment?.cartItem?.fee?.unit}{" "}
                                      {Number(
                                        payment?.cartItem?.fee?.discPrice,
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                  <CheckboxContainer
                                    disabled={
                                      !Number(
                                        payment?.cartItem?.fee?.discPrice,
                                      ) ||
                                      !Number(
                                        payment?.allowedRefund?.totalRefund,
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {payment?.refundedAt ? (
                                      <div
                                        style={{
                                          fontFamily:
                                            theme.fonts.primaryFontBold,
                                        }}
                                      >
                                        <div>Refunded</div>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            fontSize: 10,
                                            fontStyle: "italic",
                                          }}
                                        >
                                          ( {payment?.cartItem?.fee?.unit}{" "}
                                          {Number(
                                            payment?.refundAmount,
                                          ).toFixed(2)}
                                          )
                                        </div>
                                      </div>
                                    ) : (
                                      <CustomCheckbox
                                        id="refund"
                                        name={`payments[${index}].checked`}
                                        checked={
                                          values?.payments[index]?.checked
                                        }
                                        disabled={
                                          !Number(
                                            payment?.cartItem?.fee?.discPrice,
                                          ) ||
                                          !Number(
                                            payment?.allowedRefund?.totalRefund,
                                          )
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `payments[${index}].type`,
                                            "full",
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    )}
                                  </CheckboxContainer>
                                </div>
                                {payment.checked ? (
                                  <div className="history-row">
                                    <div className="content">
                                      <CustomRadioGroup
                                        radioInputs={[
                                          {
                                            label: "Full Refund",
                                            value: "full",
                                          },
                                          {
                                            label: "Refund Partial",
                                            value: "partial",
                                          },
                                        ]}
                                        value={values.payments[index].type}
                                        name={`payments[${index}].type`}
                                        {...{ values, handleChange }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          height: 50,
                                          alignItems: "center",
                                        }}
                                      >
                                        {values?.payments[index]?.type ===
                                        "partial" ? (
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <NumberFormat
                                              thousandSeparator
                                              thousandsGroupStyle="lakh"
                                              isNumericString={true}
                                              decimalScale={2}
                                              fixedDecimalScale
                                              allowNegative={false}
                                              className={"input-white"}
                                              displayType={"input"}
                                              value={
                                                values.payments[index].amount
                                              }
                                              type={"text"}
                                              customInput={CustomInputField}
                                              name={`payments[${index}].amount`}
                                              onChange={handleChange}
                                              style={{ width: 140 }}
                                              variant="outlined"
                                              error={
                                                errors?.payments?.[index]
                                                  ?.amount &&
                                                touched?.payments?.[index]
                                                  ?.amount
                                              }
                                              onBlur={(e) => {
                                                setFieldTouched(
                                                  `payments[${index}].amount`,
                                                  true,
                                                );
                                              }}
                                            />
                                            {errors?.payments?.[index]
                                              ?.amount &&
                                              touched?.payments?.[index]
                                                ?.amount && (
                                                <HelperText>
                                                  {
                                                    errors?.payments?.[index]
                                                      ?.amount
                                                  }
                                                </HelperText>
                                              )}
                                          </div>
                                        ) : (
                                          <div>
                                            {payment?.cartItem?.fee?.unit}{" "}
                                            {Number(
                                              payment?.cartItem?.fee?.discPrice,
                                            ).toFixed(2)}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </Fragment>
                            ))}
                          </Fragment>
                        )}
                      />
                    </div>
                    <div className="mt30">
                      {values?.payments?.every(
                        (el) => el.refundedAt !== null,
                      ) ? null : (
                        <CustomInputField
                          variant="outlined"
                          className={"input-height-auto"}
                          style={{ marginTop: 6 }}
                          multiline={true}
                          rows={5}
                          maxRows={5}
                          placeholder="Add comments here"
                          name={"comment"}
                          value={values?.comment}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </NoteContainer>
                  <ButtonWrapper
                    style={{
                      margin: "auto",
                      width: "418px",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "52px",
                    }}
                  >
                    <PrimaryOutlinedCTAButton
                      style={{
                        height: "48px",
                        width: "192px",
                        marginRight: 20,
                      }}
                      onClick={() => setOpenRefundModal(false)}
                    >
                      Cancel
                    </PrimaryOutlinedCTAButton>
                    {viewOnly !== true && (
                      <PrimaryCTAButton
                        type={"submit"}
                        disabled={
                          !isValid || !values?.payments.some((el) => el.checked)
                        }
                        style={{ height: "48px", width: "192px" }}
                      >
                        {btnText}
                      </PrimaryCTAButton>
                    )}
                  </ButtonWrapper>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DrawerContainer>
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
`;

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
  & .MuiFormControlLabel-root {
    font-family: openSans-ExtraBold, san-serif;
    & .MuiTypography-body1 {
      font-family: openSans-ExtraBold, san-serif;
    }
  }
  & .sub-heading {
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 16px;
    color: ${COLORS.PRIMARY_BLACK};
  }
  & .history-row {
    display: flex;
    border-bottom: solid 0.5px #acb1c2;
    & .MuiCheckbox-colorPrimary.Mui-disabled {
      cursor: not-allowed !important;
    }
  }
  & .content {
    flex: 1;
    padding: 18px 0px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    font-weight: 600;
    color: ${COLORS.PRIMARY_BLACK};
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "default")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const HelperText = styled.div`
  left: 3px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
